import React from 'react'
import './Conditions.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Conditions = () => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <div className='section-two-condition'>
                <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center", paddingTop: "1rem", paddingBottom: "1rem" }}>Terms & Conditions</h2>

                <div className='accord-condition'>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>
                                Terms of use
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.  Welcome to our website. Upkram Technologies Private Limited
                                (hereinafter referred to as “Company”, “We”, “Us”, or “Our”) owns
                                and maintains this website, www.credin.in (“Website”). Through
                                this Website, the Company provides the credit and other financial
                                products to its clients (“Services”). <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. By using the Website or the Services, you (hereinafter referred to
                                as “End User,” “You,” or “Your”, “Yourself”) are agreeing to be
                                bound by the following terms and conditions (“Terms of Use”).
                                Please note that by browsing the Website, completing any online
                                form, application or survey or availing any Service offered by the
                                Company, You will be deemed to have read, understood and accepted
                                the Terms of Use..<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                3. By mere access to the Platform or any part thereof, you signify
                                your assent to this Privacy Policy and consent to the processing
                                of your personally identifiable information (Personal Information,
                                Sensitive Personal Data or Information) to Upkram technologies pvt
                                ltd (“we” or “our” or “us” or “Credin”). This Privacy Policy is
                                incorporated into and subject to the Terms of Use of the Platform.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                4. The Company may make changes, without prior notification, to the
                                Terms of Use from time to time at the Company’s sole discretion.
                                The revised Terms of Use shall be made available on the Website.
                                You are requested to regularly visit the Website to view the most
                                current Terms of Use. The Website may require You to provide Your
                                consent to the updated Terms of Use in a specified manner before
                                any further use of the Website and/or the Services is permitted.
                                If no such separate consent is sought, Your continued use of the
                                Website or the Services, following changes to the Terms of Use,
                                will constitute Your acceptance of those changes.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>End User Agreement</Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. End User agrees to the following: 1. End User will use the Website
                                for lawful purposes only and comply with all applicable laws and
                                regulations while using and transacting on the Website or availing
                                any of the Services. 2. End User will provide authentic and true
                                information in all instances where such information is requested
                                from the End User. The Company reserves the right to confirm and
                                validate the information and other details provided by the End
                                User at any point of time. If upon confirmation, End User details
                                are found not to be true (wholly or partly), the Company has the
                                right in its sole discretion to reject the registration and debar
                                the End User from using the Website, without prior intimation
                                whatsoever. 3. End User shall access the Website and transact at
                                End User’s sole risk and are using the End User’s best and prudent
                                judgment before entering into any transaction through this
                                Website. <br /><br />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>
                                End User Eligibility
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.You understand and accept that not all products and Services
                                offered on this Website are available in all geographic areas, and
                                You may not be eligible for all the products or Services offered
                                by the Company on the Website. The Company reserves the right to
                                determine the availability and eligibility for any product or
                                Service <br /><br />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Prohibited Use</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. The Website and the Services may be used only for lawful purposes.
                                You are prohibited from using any robot, spider or any other data
                                mining technology or automatic or manual process to monitor,
                                cache, extract data from, copy or distribute the intellectual
                                property, proprietary material or other user content available on
                                or accessed through the Website. You are also prohibited from
                                using the Website in any manner that could damage, disable or
                                impair the Website, or interfere with any other party’s use, or
                                enjoyment of the Website. <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. Personal information You provide to the Company on the Website is
                                governed by the Company’s Privacy Policy located at
                                www.credin.in/privacypolicy. Your election to use the Website or
                                avail the Services indicates Your acceptance of the terms of the
                                Company’s Privacy Policy. <br /><br />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Intellectual Property Rights</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. You are aware and accept that all information, content, materials,
                                products (including, but not limited to text, content,
                                photographs, graphics, video and audio content) on the Website is
                                protected by copyright in favour of the Company under the
                                applicable copyright laws and the general intellectual property
                                law. You acknowledge that the Company owns all right, title and
                                interest in and to the Services, including without limitation all
                                intellectual property rights including, with respect to the
                                applications and the Website associated with the Services.
                                Accordingly, You agree that you will not copy, reproduce, sell,
                                redistribute, publish, enter into a database, display, perform,
                                modify, transmit, license, create derivatives from, transfer, or
                                in any way exploit, any part of any information, content,
                                materials, Services available on or through the Website, except
                                that which you may download for your own personal, non-commercial
                                use. You also agree that You will not use any robot, spider, other
                                automated device to monitor or copy any content from the Website.
                                The Company is the sole owner of the Company marks, service marks,
                                trade name and any other marks used by the Company (collectively
                                “Trademarks”). You agree not to interfere with Our rights in the
                                Trademarks, including challenging Our use, registration or
                                application to register the Trademarks, anywhere in the world, and
                                that You will not harm, misuse, or bring into disrepute any of the
                                Trademarks. The goodwill derived from using the Trademarks or any
                                part thereof, shall inure exclusively to the benefit of the
                                Company. All Trademarks, domain name, trade dress including the
                                look, feel and design of the Website, interfaces, etc., and the
                                selection and arrangements thereof, is the property of the
                                Company, unless otherwise indicated. All this is protected under
                                copyright, trademark and other applicable intellectual property
                                laws and may not be used by You, except as permitted by the
                                Company. The Website is operated by and is the sole property of
                                the Company. Any and all material on the Website is protected by
                                patents, copyrights, trademarks, and other intellectual property
                                rights that are owned by the Company or by other parties that have
                                licensed such material to the Company. Modification of the
                                materials on the Website or use of the Website, Services or other
                                materials for any purpose other than for the purpose as agreed to
                                between the parties is a violation of the said patents,
                                copyrights, trademarks and other intellectual proprietary rights,
                                and is expressly prohibited.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Disclaimer of Warranties</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE
                                MATERIALS AND CONTENT ON THIS WEBSITE ARE PROVIDED “AS IS” AND
                                WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESSED OR IMPLIED AND THE
                                COMPANY DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING,
                                BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
                                FITNESS FOR A PARTICULAR PURPOSE.
                            </Typography> <br />
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. The information, software, contents, products and services
                                included in or available through our Website may include
                                inaccuracies, typographical errors and pricing errors. We assume
                                no responsibility for any error, omission, interruption, deletion,
                                corruption, defect, delay in operation or transmission,
                                communications line failure, theft or destruction or unauthorized
                                access to, or alteration of, any End User communication or any
                                content provided through the Website. The Company is not
                                responsible for any problems or technical malfunction of any
                                telephone network or lines, electronic networks, computer online
                                systems, servers or providers, computer equipment, software,
                                failure of any email or players due to technical problems or
                                traffic congestion on the Internet or on any of the Services or
                                combination thereof, including any injury or damage to End Users
                                or to any End User’s computer related to or resulting from
                                participation or downloading materials in connection with the
                                Website or the Services. Under no circumstances shall the Company
                                be responsible for any loss or damage resulting from use of the
                                Website or the Services, or from the conduct of any End Users of
                                the Website or the Services, whether online or offline. The
                                Services are provided “as-is” and as available and the Company
                                makes no representations or warranties of any kind as to the
                                Website, the Services or the content thereof, including without
                                limitation, as to availability of the Website and/ or Services for
                                access and use. In particular, the Company expressly disclaims all
                                warranties of fitness for a particular purpose, accuracy,
                                completeness or non-infringement. We cannot guarantee and do not
                                promise any specific results from use of the Website and the
                                Services.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Indemnification</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.You agree to defend, indemnify and hold harmless the Company, Our
                                officers, directors, employees and agents, from and against any
                                and all claims, damages, obligations, losses, liabilities, costs
                                or debt, and expenses (including but not limited to attorney’s
                                fees) arising from: (i) Your use of and access to the Website;
                                (ii) Your violation of any term of these Terms of Use or any other
                                Company policy; (iii) Your violation of any third party right,
                                including without limitation any intellectual property right or
                                privacy right; (iv) or as a result of any infringing, threatening,
                                libelous, obscene, harassing or offensive material uploaded /
                                transmitted by You on the Website
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Limitation Of Liability</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.To the fullest extent permitted by applicable law, the Company
                                shall not be liable for any direct, indirect, incidental, special,
                                punitive or consequential damages, or lost profits resulting from
                                Your access to or use of the Website or Service, whether based on
                                breach of contract, breach of warranty, tort (including
                                negligence), or any other legal theory. This includes Your
                                inability to access or use (including due to modification,
                                suspension, blocking, discontinuance, cancellation, or termination
                                of the Website or any part thereof) the Website. These limitations
                                apply to any matter related to the Website, third party Internet
                                sites, programs or conduct, viruses or other disabling features,
                                incompatibility between the Website and other services, software,
                                or hardware, and any delay or failure in initiating, conducting,
                                or completing any transmission or transaction in connection with
                                the Website in an accurate or timely manner. These limitations
                                also apply even if this remedy does not fully compensate You for
                                any losses, or fails its essential purpose; or even if the Company
                                knew or should have known about the possibility of the damages.
                                Without limiting the foregoing, under no circumstances shall the
                                Company be held liable for any delay or failure in performance
                                resulting directly or indirectly from acts of nature, forces, or
                                causes beyond its reasonable control, including, without
                                limitation, Internet failures, computer equipment failures,
                                telecommunication equipment failures, other equipment failures,
                                electrical power failures, strikes, labor disputes, riots,
                                insurrections, civil disturbances, shortages of labor or
                                materials, fires, floods, storms, explosions, acts of God, war,
                                governmental actions, orders of domestic or foreign courts or
                                tribunals, non-performance of third parties, or loss of or
                                fluctuations in heat, light, or air conditioning.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Third Party Websites</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.You understand and accept that the Company is not responsible for
                                the availability of content, or other services on third-party
                                sites linked from the Website. You are aware that the access of
                                hyperlinks to other internet websites are at your own risk, and
                                the content, accuracy, opinions expressed, and other links
                                provided by these websites are not verified, monitored or endorsed
                                by the Company in any way. The Company does not make any
                                warranties, and expressly disclaims all warranties, express or
                                implied, including without limitation, those of merchantability
                                and fitness for a particular purpose, title or non-infringement
                                with respect to any information or services or products that are
                                available or advertised or sold through these third-party
                                websites.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Choice Of Law And Jurisdiction</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. This Agreement shall be construed in accordance with the
                                applicable laws of India. The Courts at vadodara shall have
                                exclusive jurisdiction in any proceedings arising out of this
                                Terms of Use
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Severability</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. If any term, provision, covenant or restriction of these Terms of
                                Use is held by a court of competent jurisdiction to be invalid,
                                void or unenforceable, the remainder of the terms, provisions,
                                covenants and restrictions of these Terms of Use shall remain in
                                full force and effect and shall in no way be affected, impaired or
                                invalidated.
                            </Typography>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>No Waiver</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. The rights and remedies available under this Terms of Use may be
                                exercised as often as necessary and are cumulative and not
                                exclusive of rights or remedies provided by law. It may be waived
                                only in writing. Delay in exercising or non-exercise of any such
                                right or remedy does not constitute a waiver of that right or
                                remedy, or any other right or remedy.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>General</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.  You cannot assign or otherwise transfer the Terms of Use, or any
                                right granted hereunder to any third party. These Terms of Use
                                comprise the full and complete agreement between You and the
                                Company with respect to the use of the Cloud Service and
                                supersedes and cancels all prior communications, understandings
                                and agreements between You and the Company, whether written or
                                oral, expressed or implied with respect thereto
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Cancellation Policy</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.  Since Credin is incurring costs and dedicating time, manpower,
                                technology resources and effort to your service and loan
                                processing therefore cancellation of loan is not allowed and no
                                amount/ fees/ charges will be refunded.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Refund Policy</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. During an application processing, Credin charges fee at defined
                                rates (Mentioned in the FAQ) and communicated to the applicant
                                well in advance. Refund cannot be provided for charged fee because
                                resources and man hours spent on delivering the service are
                                non-returnable in nature. Further, we can’t refund or credit any
                                money paid to government entities such as taxes, or to other third
                                parties with a role in processing loan application. Under any
                                circumstance, fees and charges are non-refundable. For any
                                grievance you can reach out to help@credin.in
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Force Majeure</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. Credin shall not be considered in breach of any terms of service,
                                and shall not be liable to the Client for any cessation,
                                interruption, or delay in the performance of its obligations by
                                reason of earthquake, flood, fire, storm, lightning, drought,
                                landslide, hurricane, cyclone, typhoon, tornado, natural disaster,
                                act of God or the public enemy, epidemic, famine or plague, action
                                of a court or public authority, change in law, explosion, war,
                                terrorism, armed conflict, labor strike, lockout, boycott or
                                similar event beyond our reasonable control, whether foreseen or
                                unforeseen (each a “Force Majeure Event”).
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Credit Bureau T &amp; C</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.  In connection with submission of the application for my credit
                                information (“Consumer Credit Information”) offered by Equifax
                                (“CIC”) through Upkram Technologies Pvt Ltd (referred to as the
                                “Company”) and delivery of the Consumer Credit Information to the
                                Company, I hereby acknowledge and agree to the following:
                            </Typography> <br />
                            <Typography sx={{ color: "#5d80a7" }}>
                                2.   A. The Company is my lawfully appointed agent and he / it has
                                agreed to be my agent for the purposes, including, without
                                limitation, to receive the Consumer Credit Information from CIC on
                                my behalf and use it in the manner consistent with the Agreement
                                entered into between the Company and CIC, and the Company has
                                granted its consent for being appointed for the aforesaid
                                purpose.<br />B. I grant my unconditional consent to the Company
                                to receive the Consumer Credit Information from CIC on my behalf
                                and use it in the manner consistent with the Agreement entered
                                into between the Company and CIC, as the case may be, and the
                                Company has granted its consent for being appointed for the
                                aforesaid purpose. I hereby represent and acknowledge that: (a) I
                                have carefully read the terms and conditions of the Agreement
                                entered into between the Company and CIC understood the same; or
                                (b) the Terms of Understanding in relation to the use of the
                                Consumer Credit Information has been agreed between me and the
                                Company.<br />C. I hereby expressly grant unconditional consent
                                to, and direct, CIC to deliver and / or transfer my Consumer
                                Credit Information to the Company on my behalf.<br />D. I shall
                                not hold CIC responsible or liable for any loss, claim, liability,
                                or damage of any kind resulting from, arising out of, or in any
                                way related to: (a) delivery of my Consumer Credit Information to
                                the Company; (b) any use, modification or disclosure by the
                                Company of the contents, in whole or in part, of my Consumer
                                Credit Information, whether authorized or not; (c) any breach of
                                confidentiality or privacy in relation to delivery of my Consumer
                                Credit Information to the Company ; (d) for any use made by the
                                Company which is contrary to the Agreement entered into between
                                the Company and CIC<br />E. I acknowledge and accept that: (a) CIC
                                has not made any promises or representations to me in order to
                                induce me to provide my Consumer Credit Information or seek any
                                consent or authorization in this regard; and (b) the
                                implementation of the Agreement between CIC and the Company is
                                solely the responsibility of the Company.<br />F. I agree that I
                                may be required to record my consent / provide instructions
                                electronically and in all such cases I understand that by clicking
                                on the “I Accept” button below, I am providing “written
                                instructions” to Company authorizing Company to obtain my Consumer
                                Credit Information from my personal credit profile from Equifax. I
                                further authorize Company to obtain such information solely to
                                confirm my identity and display my Consumer Credit Information to
                                me. Further in all such cases by checking this box and clicking on
                                the Authorize button, I agree to the terms and conditions,
                                acknowledge receipt of CIC privacy policy and agree to its terms,
                                and confirm my authorization for Company to obtain my Consumer
                                Credit Information.<br />G. I understand that in order to deliver
                                the product to me, I hereby authorize Company, to obtain my
                                Consumer Credit Information from CIC.<br />H. By submitting this
                                registration form, I understand that I am providing express
                                written instructions for Company to request and receive
                                information about me from third parties, including but not limited
                                to a copy of my consumer credit report and score from consumer
                                reporting agencies, at any time for so long as I have an active
                                Company account. I further authorize Company to retain a copy of
                                my information for use in accordance with Company’s Terms of Use
                                and Privacy Policy.<br />I. I UNDERSTAND THAT THE PRODUCT IS
                                PROVIDED ON AN “AS-IS”, “AS AVAILABLE” BASIS AND CIC EXPRESSLY
                                DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                                NON-INFRINGEMENT.<br />J. I shall not sue or otherwise make or
                                present any demand or claim, and I irrevocably, unconditionally
                                and entirely release, waive and forever discharge CIC, its
                                officers, directors, employees, agents, licensees, affiliates,
                                successors and assigns, jointly and individually (hereinafter
                                “Releasee”), from any and all manner of liabilities, claims,
                                demands, losses, claims, suits, costs and expenses (including
                                court costs and reasonable attorney fees) (“Losses”), whatsoever,
                                in law or equity, whether known or unknown, which I ever had, now
                                have, or in the future may have against the Releasee with respect
                                to the submission of my Consumer Credit Information and / or my
                                decision to provide CIC with the authority to deliver my Consumer
                                Credit Information to the Company. I agree to defend, indemnify,
                                and hold harmless the Releasee from and against any and all Losses
                                resulting from claims made against CIC L by third parties arising
                                from and in connection with this letter.<br />K. I agree that the
                                terms of this confirmation letter shall be governed by the laws of
                                India and shall be subject to the exclusive jurisdiction of the
                                courts located in Mumbai in regard to any dispute arising hereof.
                                CIC is entitled to assign its rights hereunder to any third person
                                without taking my prior written consent
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default Conditions