import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home/Home.js";
import AboutUs from "./pages/AboutUs/AboutUs.js";
import Product from "./pages/Product/Product.js";
import Header from "./Common/Header.js";
import Footer from "./Common/Footer.js";
import NbfcPartners from "./pages/NbfcPartners/NbfcPartners.js";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.js";
import Team from "./pages/Team/Team.js";
import Conditions from "./pages/Conditions/Conditions.js";
import Faq from "./pages/Faq/Faq.js";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/product" element={<Product />} />
        <Route path="/nbfc/partners" element={<NbfcPartners />} />
        <Route path="/privacy/policy" element={<PrivacyPolicy />} />
        <Route path="/our/team" element={<Team />} />
        <Route path="/terms/conditions" element={<Conditions />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
