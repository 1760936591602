import React, { useState, useEffect } from 'react';
import './Header.css';
// import contact from "../Images/Vector.svg";
import Credinlogo from "../Images/Credinlogo.svg";
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenWidth();

    const handleResize = () => {
      checkScreenWidth();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <nav className="navbar">
      <div className="logo">
        <a href="/" >
          <img style={{ width: "8rem" }} src={Credinlogo} className="contact-logo" alt="logo" />
        </a>
      </div>
      <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <a href="/" className="navbar-link">Home</a>
        <a href="/product" className="navbar-link">Product</a>
        <a href="/about" className="navbar-link">About us</a>
      </div>
      {isMobile && (
        
        <div className="navbar-toggle" onClick={toggleNavbar}>
          <MenuIcon sx={{fontSize:"2rem"}}/>
        </div>
      )}
    </nav>
  );
}

export default Header;
