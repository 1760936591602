import React, { useState } from 'react'
import './Faq.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
    const [expanded, setExpanded] = React.useState(false);
    const [something, setSomething] = useState("application");
    const [activeOne, setactiveOne] = useState(true);
    const [activeTwo, setactiveTwo] = useState(false);
    const [activeThree, setactiveThree] = useState(false);
    const [activeFour, setactiveFour] = useState(false);

    const handleApplication = (event) => {
        console.log(event.target.value);
        setactiveOne(!activeOne);
        setactiveTwo(false);
        setactiveThree(false);
        setactiveFour(false);
        setSomething(event.target.value)
    };
    const handleAmount = (event) => {
        console.log(event.target.value);
        setactiveTwo(!activeTwo);
        setactiveThree(false);
        setactiveFour(false);
        setactiveOne(false);
        setSomething(event.target.value)
    };
    const handleTechnical = (event) => {
        console.log(event.target.value);
        setactiveThree(!activeThree);
        setactiveFour(false);
        setactiveTwo(false);
        setactiveOne(false);
        setSomething(event.target.value)
    };
    const handleRepayments = (event) => {
        console.log(event.target.value);
        setactiveFour(!activeFour);
        setactiveThree(false);
        setactiveTwo(false);
        setactiveOne(false);
        setSomething(event.target.value)
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <div className='section-one-faq'>
                <p style={{ textAlign: "center", paddingTop: "1rem", fontWeight: "500", fontSize: "2rem", color: "#0c4282" }}>Want to know something from Us?</p>
                <div className="four-div-faq">
                    <button style={{ color: activeOne ? "#0c4282" : "gray" }} className="btn-class-faq" value="application" onClick={handleApplication}>Loan Application</button>
                    <button style={{ color: activeTwo ? "#0c4282" : "gray" }} className="btn-class-faq" value="amount" onClick={handleAmount}>Loan Amount</button>
                    <button style={{ color: activeThree ? "#0c4282" : "gray" }} className="btn-class-faq" value="technical" onClick={handleTechnical}>Technical</button>
                    <button style={{ color: activeFour ? "#0c4282" : "gray" }} className="btn-class-faq" value="repayments" onClick={handleRepayments}>Repayments</button>
                </div>
                    <div className='accordion-faq'>
                        {something === "application" ? (
                            <>
                                <Accordion style={{ marginBottom: "1rem", marginTop: "2rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>
                                            What are the KYC documents required for a personal loan?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            We collect the following documents from the customer for KYC.
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            1. One Color Photograph.
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            2. Identity Proof: PAN Card.
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            3. Residence Proof: Masked Aadhar, Driving License, Voter-ID, Passport.
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            4. For current Residence Proof: Electricity Bill/ Rent Agreement
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            All the above documents are fulfilled and validated digitally.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600",fontSize:"1rem" }}>Apart from KYC, what other details/documents do you require for processing the application?</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            1. For Pratham- Salaried Personal Loan: Company ID, 6-Month Bank Statement
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            2. For Aarambh- Self-employed Personal Loan: Business Registration proof, 6 Month Bank Statement
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            3. For Shiksha- School Fees in EMI: 6 Month bank statement.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>
                                            Do I need to provide KYC documents every time I take a loan from Credin?
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            No, the documents are not required to be provided again and again. Once your documents have been successfully verified and your Credin profile is confirmed, you need not to re-submit your documents. However, you are required to notify us and upload new documents for verification in case there are any changes in your employment, salary, or residence
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>How to apply for the loan at Credin and how much time it’ll take?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            you can apply through the following ways for a loan on Credin.
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            1. Download the Credin App from the Google Play store and apply (Pls add a Play-store app link here if possible)
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            2. You can walk into our office/ branch mentioned on the website and apply there.
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            3. You can complete the loan application digitally and get the eligibility within 24 hours if all the details are filled in properly.
                                        </Typography>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            4. Final disbursal may take up to 48 hours provided all the documents and details are correct and in order.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>What are the advantages of availing personal loan from credin apart from any other bank?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            1.It usually takes anywhere from 4-7 days to avail a Personal Loan from a bank, which also requires a lot of documentation and frequent visits to the branch office. However, the Credin Personal Loan process is entirely online, and it hardly takes 15 minutes for the entire process depending on your information. The amount is transferred directly to your bank account, available for use anytime and anywhere as per your convenience.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>Is it possible to get the loan amount transferred to a joint bank account of customer?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Yes, it is possible to get the loan amount transferred to a bank account jointly held by you. However, you should be the primary account holder of that joint bank account and it should be your salary/business account.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ) : ""}

                        {something === "amount" ? (
                            <>
                                <Accordion style={{ marginBottom: "1rem", marginTop: "2rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>
                                            Why do you require salary account details?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            It is mandatory to fill in the details of your company-provided salary account if you want to apply for Pratham. We require this information as a part of our process to check and validate your employment and salary details provided. We also need this information to cross-check your transactions as recorded in your bank statement, based on which your creditworthiness would be decided. There is no reason to worry, though. These details are completely safe with us and won’t be shared with anybody                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>How will my loan amount be decided?</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Your loan amount is decided based on the various parameters which includes authenticity of your documents, previous repayment history, Credit Score, Monthly income, EMI obligations, spending behaviour, repayment capabilities etc.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>
                                            Is Credin connected with any credit bureau?
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            All the Credin partnered NBFCs have collaborated with all four of these Credit Information Companies, and they send the repayment history of all Credin customers every month to these companies
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>How is my credit score connected to the Personal Loan that I can avail from Credin?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Once you take a Loan from Credin and repay it back on time, your credit score improves. This helps you avail a bigger loan at a later stage from a bank or any other financial institution and you will get it at a lower interest rate. However, if you don’t repay the loan on time or default on the same, then your credit score will go down. And, it becomes nearly impossible to avail a loan from a bank due to your poor repayment history. Even if a loan is approved, the interest rate that will be charged will be considerably higher.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>What is the minimum and maximum loan I can avail from Credin?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            You can avail loan from ₹ 50,000 to ₹ 30,00,000 (depending on type of loan).
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>Is Credin connected with any credit bureau?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            All the Credin partnered NBFCs have collaborated with all four of these Credit Information Companies, and they send the repayment history of all Credin customers every month to these companies.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ) : ""}

                        {something === "technical" ? (
                            <>
                                <Accordion style={{ marginBottom: "1rem", marginTop: "2rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>
                                            I am not able to login to the app for registration, what do I do?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            You need to provide the requisite app permissions before you can login to Credin. If you are not getting the permissions request, please enable the same on ‘Settings’ section of your phone. If the problem still persist, you can also drop us a mail on help@credin.in and we shall look into the same.                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>I am facing technical issue during Loan Application, what do I do?</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Send us a video with the issue flow or screenshot, along with your registered phone number and mobile device model name on help@credin.in and we shall reach out to you at the earliest.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>
                                            I am unable to get the OTP for E-Sign on my Phone?
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            You will receive OTP once you click on the ‘Aadhaar E-sign’ option which is sent by UIDAI on the registered mobile number only. If you don’t receive the OTP then either there is an issue with your service provider and you are advised to try again after some time or your mobile number is not Linked to your Aadhaar. The OTP would also be delivered to the E-mail ID linked to your Aadhaar. If you don’t receive OTP on your email and mobile, please reach out to UIDAI or your nearest Aadhaar centre.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>My KYC documents are correct but they are not getting verified. What do I do?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Please ensure that you are taking a clear photo of the original documents. Taking a photo of an existing photo (from another mobile screen or computer screen) may not work.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>Can I send documents over company email?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            We support uploading of documents through the app or form only.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>I don’t have a Facebook or Google account, can I still use the Credin App?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Yes, a Facebook or Google account is not mandatory for signing up on Credin. You can register through mobile number.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ) : ""}

                        {something === "repayments" ? (
                            <>
                                <Accordion style={{ marginBottom: "1rem", marginTop: "2rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{color: "#0c4282", fontWeight: "600" }}>
                                            How can I repay my loan?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Your EMI gets auto-deducted from your account as per the EMI Schedule via NACH-ECS.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>What happens if I do not pay on time?</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            There’ll be penalty charges on the overdue loan amount. Your Credit score will be impacted and that will make it difficult for you to take loans in the future from any financial institution. Companies also check an individual's credit score and may not offer you employment if your credit score is bad.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>
                                            Do you have a cash payment option?
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            No
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>Can I repay the loan before the due date?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            Not until the 6 EMI has been paid regularly.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>Can I reschedule or postpone my EMI date?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            No. Credin does not provide any option to reschedule or postpone your EMI date.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography sx={{ color: "#0c4282", fontWeight: "600" }}>What if I want to foreclose/preclose the loan?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: "#6d8eb4" }}>
                                            You can foreclose the loan after 6 EMIs have been paid regularly and on time. The charges in this case is 5.9% (inclusive of GST)?
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ) : ""}
                    </div>
            </div>
        </div>
    )
}

export default Faq