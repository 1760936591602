import React from 'react'
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import hero from "../../Images/HERO.svg";
import group from "../../Images/Group 41.svg";
import ai from "../../Images/Artificial intelligence in finance, machine learning in finance.svg";
import credit from "../../Images/credit cards.svg";
import education from "../../Images/Educational video for online education.svg";
import mobile from "../../Images/image 5.svg";
import arvog from "../../Images/arvog.jpeg";
import ctl from "../../Images/CTLLogo-01.png";
import fintree from "../../Images/Fintree.jpg";
import hdb from "../../Images/hdb.svg";
import lendtree from "../../Images/lendtree-1.jpg";
import maxemo from "../../Images/maxemo.jpeg";
import pincab from "../../Images/pincab.png";
import ramaiah from "../../Images/Ramaiah LOGO.jpg";
import roha from "../../Images/roha.png";

// import rectangle from "../../Images/Rectangle 673 (1).jpg";
// import frames from "../../Images/Frame 85 (1).svg";
// import digital from "../../Images/Digital illustrator workplace.svg";
import bank from "../../Images/Bank deposits and business dividends.svg";
import marketing from "../../Images/Marketing with customer segmentation in mind.svg";
import shopping from "../../Images/shopping credit card.svg";
import smart from "../../Images/Smart home application connecting to devices.svg";
import './Home.css';
import Carousel from "react-elastic-carousel";
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import arthmate from "../../Images/Arthmate-final-logo.png";
import northern from "../../Images/Northern Arc logo.jpg";
import trimurti from "../../Images/Trimurti Fincap Logo (4).jpg";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import video from "../../Images/CredIN-Introduction.mp4";
import videoOne from "../../Images/Credin-Celebration.mp4";
import cattle from "../../Images/Chart.svg";

// const breakPoints = [
//     { width: 1, itemsToShow: 1 },
//     { width: 550, itemsToShow: 2, itemsToScroll: 2 },
//     { width: 768, itemsToShow: 3 },
//     { width: 1200, itemsToShow: 4 },
// ];
const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
];

const Home = () => {
    // const [value, setValue] = React.useState('1');

    // const handleChange = (event, newValue) => {
    //     console.log(event.target.value);
    //     console.log(newValue);
    //     setValue(newValue);
    // };

    return (
        <div className='container'>
            <div className='section-one' >
                <p className="section-one-content">MOST INCLUSIVE LENDING <br /> FINTECH PLATFORM  <span className="animate-text"></span></p>
                <img src={hero} className="hero-logo" alt="logo" />
            </div>
            <div className='enabling-lives'>
                <div>
                    <p className='enabling-para'>Enabling Lives</p>
                </div>
                <div className='enabling-lives-content'>
                    <div>
                        <h1>35k</h1>
                        <p>Borrowers</p>
                    </div>
                    <div>
                        <h1><CurrencyRupeeIcon />300 CR+</h1>
                        <p>Loans Processed </p>
                    </div>
                    <div>
                        <h1><CurrencyRupeeIcon />60 CR+</h1>
                        <p>Credit Enabled for Priority <br /> Sector </p>
                    </div>
                    <div>
                        <h1>12</h1>
                        <p>NBFC Partners</p>
                    </div>
                </div>
            </div>
            <div className='section-two'>
                <p className='section-two-para'>HIGHLIGHTS OF <span style={{ color: "#0c4282" }}>CRED</span><span style={{ color: "#e03b16" }}>IN</span></p>
                <div className='section-two-content' >
                    <div>
                        <img src={group} className="group" alt="logo" />
                    </div>
                    <div >
                        <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                            <div className='section-two-div'>
                                <img src={mobile} className="mobile" alt="logo" />
                                <p className='highlight-building'>Unsecured/Secured Business Loans to Dairy Farmers </p>
                            </div>
                            <div className='section-two-div'>
                                <img src={education} className="education" alt="logo" />
                                <p className='highlight-initiating'>Inclusive Lending Platform for Fintech Experience</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                            <div className='section-two-div'>
                                <img src={ai} className="ai" alt="logo" />
                                <p className='highlight-promoting'>Fintech Inclusion for Larger Social Impact</p>
                            </div>
                            <div className='section-two-div'>
                                <img src={credit} className="credit" alt="logo" />
                                <p className='highlight-finance'>Fintech experience to the Unserved/Underserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='section-three'>
                <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center" }}>Our Lending Partners</h2>
                <div className='partners-logo'>
                    <img src={arvog} className="" alt="logo" style={{ height: "3.5rem" }} />
                    <img src={ctl} className="" alt="logo" style={{ height: "4rem" }} />
                    <img src={fintree} className="" alt="logo" style={{ height: "4.5rem" }} />
                    <img src={hdb} className="" alt="logo" style={{ height: "3rem" }} />
                    <img src={lendtree} className="" alt="logo" style={{ height: "3.5rem" }} />
                    <img src={maxemo} className="" alt="logo" style={{ height: "3rem" }} />
                    <img src={pincab} className="" alt="logo" style={{ height: "3rem" }} />
                    <img src={ramaiah} className="" alt="logo" style={{ height: "4rem" }} />
                    <img src={arthmate} className="" alt="logo" style={{ height: "4rem" }} />
                    <img src={northern} className="" alt="logo" style={{ height: "4rem" }} />
                    <img src={trimurti} className="" alt="logo" style={{ height: "4.5rem" }} />
                </div>
            </div> */}

            <div style={{ borderBottom: "1px solid black" }} className='section-three'>
                <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center" }}>Our Lending Partners</h2>
                <div style={{ paddingBottom: "1rem" }}>
                    <Carousel style={{ paddingTop: "2rem" }} breakPoints={breakPoints} itemsToShow={3} enableAutoPlay={true} autoPlaySpeed={3000} pagination={false}>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={arvog} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={ctl} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={fintree} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={hdb} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={lendtree} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={maxemo} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={pincab} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={ramaiah} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={arthmate} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={northern} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={trimurti} className="carousel-images-home" alt="logo" />
                        </div>
                        <div style={{ border: "none" }} className='carousel-one-home'>
                            <img src={roha} className="carousel-images-home" alt="logo" />
                        </div>
                    </Carousel>
                </div>
            </div>

            <div className='section-four'>
                <h1 style={{ color: "#0c4282", paddingTop: "1rem", textDecoration: "underline", fontSize: "2rem", textAlign: "center" }}>How we process Cattle Loan</h1>
                <div className='cattle'>
                    <img src={cattle} className="cattle-image" alt="logo" />
                </div>
            </div>

            {/* <Box sx={{ backgroundColor: "#f7fef9", paddingBottom: "2rem"}}>
                <TabContext value={value}>
                    <Box  sx={{ display: "flex", justifyContent: "center",paddingTop: "2rem"}}>
                        <TabList  onChange={handleChange} variant="scrollable" scrollButtons="auto" className='tablist-box'>
                            <Tab sx={{ fontSize: "1rem" }} label="Credin at RBI Innovation Hub " value="1" /><KeyboardArrowUpIcon sx={{ marginTop: "0.7rem" }} />
                            <Tab sx={{ fontSize: "1rem"  }} label="Credin @Slush2023 representing India " value="2" /><KeyboardArrowDownIcon sx={{ marginTop: "0.7rem" }} />
                            <Tab sx={{ fontSize: "1rem" }} label="Credin covered by Forbes India" value="3" /><KeyboardArrowDownIcon sx={{ marginTop: "0.7rem" }} />
                        </TabList>
                    </Box>
                    <Box  sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                        {value === "1" ? (
                            <TabPanel className="box-tab" value="1">
                                <img style={{ height: "22rem", border: "1px solid #131a20", backgroundColor: "#6a8eb2", borderRadius: "9px" }} src={digital} className="" alt="logo" />
                                <p className="para-tab"><span style={{ color: "#0c4282", textDecoration: "underline" }}>CRED</span><span style={{ color: "#e24724", textDecoration: "underline" }}>IN</span> leverages a robust digital platform to reach a wide audience of potential borrowers. Through the official website and mobile applications, users can conveniently explore loan products, submit applications, and track the progress of their requests. The digital platform ensures accessibility, ease of use, and quick responses to customer needs.</p>
                            </TabPanel>
                        ) : ""}
                        {value === "2" ? (
                            <TabPanel className="box-tab" value="2">
                                <img style={{ height: "15rem", border: "1px solid #131a20", backgroundColor: "#6a8eb2", borderRadius: "7px" }} src={frames} className="" alt="logo" />
                                <p className="para-tab"><span style={{ color: "#0c4282", textDecoration: "underline" }}>CRED</span><span style={{ color: "#e24724", textDecoration: "underline" }}>IN</span> leverages a robust digital platform to reach a wide audience of potential borrowers. Through the official website and mobile applications, users can conveniently explore loan products, submit applications, and track the progress of their requests. The digital platform ensures accessibility, ease of use, and quick responses to customer needs.</p>
                            </TabPanel>
                        ) : ""}

                        {value === "3" ? (
                            <TabPanel className="box-tab" value="3">
                                <img style={{ height: "20rem", border: "1px solid #131a20", backgroundColor: "#6a8eb2", borderRadius: "7px" }} src={rectangle} className="" alt="logo" />
                                <p className="para-tab"><span style={{ color: "#0c4282", textDecoration: "underline" }}>CRED</span><span style={{ color: "#e24724", textDecoration: "underline" }}>IN</span> leverages a robust digital platform to reach a wide audience of potential borrowers. Through the official website and mobile applications, users can conveniently explore loan products, submit applications, and track the progress of their requests. The digital platform ensures accessibility, ease of use, and quick responses to customer needs.</p>
                            </TabPanel>
                        ) : ""}
                    </Box>
                </TabContext>
            </Box> */}

            <div className='section-five'>
                <h2 style={{ textDecoration: "underline", paddingTop: "1rem", fontSize: "2rem", textAlign: "center" }}>Impact Of <span style={{ color: "#0c4282" }}>CRED</span><span style={{ color: "#e24724" }}>IN</span></h2>
                <div className='impact-divs'>
                    <div className='section-five-div'>
                        <img src={shopping} className="shopping" alt="logo" />
                        <p className='highlight-purpose'>Purpose-led Lending for Income Generation</p>
                    </div>
                    <div className='section-five-div'>
                        <img src={marketing} className="marketing" alt="logo" />
                        <p className='highlight-sustainable'>Achieving 4 UN Sustainable Development Goals</p>
                    </div>
                    <div className='section-five-div'>
                        <img src={smart} className="smart" alt="logo" />
                        <p className='highlight-covering'>Covering Bharat with 100% Digital Lending </p>
                    </div>
                    <div className='section-five-div'>
                        <img src={bank} className="bank" alt="logo" />
                        <p className='highlight-dairy'>27% coverage of women Dairy Farmers</p>
                    </div>
                </div>
            </div>

            <div style={{ borderBottom: "1px solid black" }} className='section-six'>
                <h2 style={{ textAlign: "center", color: "#0c4282", fontSize: "2rem", paddingTop: "1rem" }}>Discover what makes <br /> CRED<span style={{ color: "#e03b16" }}>IN</span> Exceptional</h2>
                <Carousel style={{ paddingTop: "1rem" }} itemsToShow={1} enableAutoPlay={true} autoPlaySpeed={3000} pagination={false}>
                    <div style={{ border: "none" }} className='carousel-item-one'>
                        <video width="1200" height="600" autoPlay muted controls>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <div style={{ border: "none" }} className='carousel-item-two'>
                        <video width="1200" height="600" autoPlay muted controls>
                            <source src={videoOne} type="video/mp4" />
                        </video>
                    </div>
                    <div style={{ border: "none" }} className='carousel-item-three'>
                        <video width="1200" height="600" autoPlay muted controls>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <div style={{ border: "none" }} className='carousel-item-four'>
                        <video width="1200" height="600" autoPlay muted controls>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}

export default Home