import React, { useState, useEffect } from 'react'
import "./Product.css"
import Carousel from "react-elastic-carousel";
import after from "../../Images/after.svg";
import before from "../../Images/before.svg";
import group from "../../Images/Group 120.svg";
import pagi from "../../Images/PAGI.jpg";
import pandya from "../../Images/PANDYA.jpg";
import patel from "../../Images/PATEL.jpg";
import rabari from "../../Images/RABARI.jpg";
import az from "../../Images/Frame 272.jpg";
import bz from "../../Images/Frame 273.jpg";
import three from "../../Images/IMG_9661.jpg";
import four from "../../Images/IMG_9662.jpg";
import five from "../../Images/IMG_9664.jpeg";
import six from "../../Images/IMG_9665.jpeg";


// import Accordion from '@mui/material/Accordion';
// import aaaa from "../../Images/image\ 18\ \(1\).svg"
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ai from "../../Images/Artificial intelligence in finance, machine learning in finance.svg";
// import credit from "../../Images/credit cards.svg";
// import education from "../../Images/Educational video for online education.svg";
// import mobile from "../../Images/image 5.svg";

const Product = () => {
  // const [expanded, setExpanded] = React.useState(false);
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];
  const breakPoint = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <Carousel style={{ borderBottom: "2px solid black" }} itemsToShow={1} enableAutoPlay={true} breakPoints={breakPoint} autoPlaySpeed={3000} pagination={false}>
        <div className='carousel-product'>
          <img src={pagi} alt="logo" className="carousel-product-images" />
          {/* <div className="content">
            <p className="caro-para">Credin Aarambh is here to create true financial inclusion through easy access to affordable credit for all the businesses under-served by banks. </p>
          </div> */}
        </div>
        <div style={{ border: "none" }} className='carousel-product'>
          <img src={pandya} className="carousel-product-images" alt="logo" />
          {/* <div className="content">
            <p className="caro-para">Credin Aarambh is here to create true financial inclusion through easy access to affordable credit for all the businesses under-served by banks. </p>
          </div> */}
        </div>
        <div style={{ border: "none" }} className='carousel-product'>
          <img src={patel} className="carousel-product-images" alt="logo" />
          {/* <div className="content">
            <p className="caro-para">Credin Aarambh is here to create true financial inclusion through easy access to affordable credit for all the businesses under-served by banks. </p>
          </div> */}
        </div>
        <div style={{ border: "none" }} className='carousel-product'>
          <img src={rabari} className="carousel-product-images" alt="logo" />
          {/* <div className="content">
            <p className="caro-para">Credin Aarambh is here to create true financial inclusion through easy access to affordable credit for all the businesses under-served by banks. </p>
          </div> */}
        </div>
      </Carousel>
      <div className='product-section-two'>
        <div className="product-content">
          <div className='product-section-div'>
            <p className='highlight-inclusive'>Inclusive</p>
            <p className='highlight-covers'>Covers all the business segments</p>
          </div>
          <div className='product-section-div'>
            <p className='highlight-inclusive'>Convenient</p>
            <p className='highlight-covers'>Get the service at your door step </p>
          </div>
          <div className='product-section-div'>
            <p className='highlight-inclusive'>Transparent</p>
            <p className='highlight-covers'>No hidden Charges</p>
          </div>
          <div className='product-section-div-fourth'>
            <p className='highlight-inclusive'>Cattle/Dairy Loan</p>
            <p className='highlight-covers-fourth'>Ticket Size-1.2-15 Lakhs</p>
            <p className='highlight-texts'>Tenure- 24-72 Months</p>
          </div>
        </div>

      </div>

      <div className='product-section-three'>
        <h1 style={{ color: "#0c4282", paddingTop: "1rem", textDecoration: "underline", fontSize: "2rem", textAlign: "center" }}>How we process Cattle Loan</h1>
        <div className='cattle-images'>
          <img src={group} className="" alt="logo" style={{ height: "12rem", paddingTop: "1rem" }} />
          <p style={{ textAlign: "center", paddingBottom: "1rem" }}>Cattle Loan</p>
          {window.innerWidth > 600 ? (
            <img src={before} className="" alt="logo" style={{ height: "10rem", paddingBottom: "2rem" }} />
          ) : (
            <img src={az} className="" alt="logo" style={{ width: "20rem", margin: "auto", paddingBottom: "2rem" }} />
          )
          }
          {window.innerWidth > 600 ? (
            <img src={after} className="" alt="logo" style={{ height: "20rem", paddingBottom: "1rem" }} />
          ) : (
            <img src={bz} className="" alt="logo" style={{ width: "20rem", margin: "auto", paddingBottom: "2rem" }} />
          )
          }
          <p style={{ textAlign: "justify", padding: "1rem 2rem 2rem", color: "#6d8eb4" }}>Embarking on your cattle venture with Aarambh is a seamless journey. Begin by applying online, specifying details about the livestock you plan to invest in. Submit necessary documents as our smart algorithms evaluate your application promptly. Upon approval, proceed to purchase your desired cattle. The disbursed loan facilitates a stress-free acquisition, bringing your cattle business to life. Aarambh simplifies the process, making your cattle loan experience both efficient and rewarding.</p>
        </div>
      </div>

      <div style={{ borderBottom: "1px solid black" }} className='section-six'>
        <h2 style={{ textAlign: "center", color: "#0c4282", fontSize: "2rem", paddingTop: "1rem" }}>Discover what makes <br /> CRED<span style={{ color: "#e03b16" }}>IN</span> Exceptional</h2>
        <div style={{ paddingBottom: "1rem" }}>
          <Carousel style={{ paddingTop: "2rem" }} breakPoints={breakPoints} itemsToShow={3} enableAutoPlay={true} autoPlaySpeed={3000} pagination={false}>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={pagi} className="carousel-images" alt="logo" />
            </div>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={pandya} className="carousel-images" alt="logo" />
            </div>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={patel} className="carousel-images" alt="logo" />
            </div>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={three} className="carousel-images" alt="logo" />
            </div>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={four} className="carousel-images" alt="logo" />
            </div>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={five} className="carousel-images" alt="logo" />
            </div>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={six} className="carousel-images" alt="logo" />
            </div>
            <div style={{ border: "none" }} className='carousel-one-product'>
              <img src={rabari} className="carousel-images" alt="logo" />
            </div>
          </Carousel>
        </div>
      </div>

      {/* <div className='section-seven' style={{ borderBottom: "1px solid #5d80a7", paddingBottom: "1rem" }}>
        <h2 style={{ textAlign: "center", color: "#0c4282", paddingBottom: "1rem", paddingTop: "1rem", fontSize: "2rem", fontWeight: "bold", textDecoration: "underline" }}>FAQ</h2>
        <p style={{ paddingBottom: "1rem", textAlign: "center", color: "gray", fontSize: "1.4rem" }}>Have questions? We are here to help.</p>
        <div className='accord'>
          <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="para-faq">
                How do I apply for an Aarambh loan?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#6d8eb4" }}>
                Applying for an Aarambh loan is easy! Visit our website, navigate to the "Apply Now" section, and follow the simple steps to submit your application online. Our team will guide you through the rest.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className="para-faq">What documents are required for the application?</Typography>

            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#6d8eb4" }}>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
                varius pulvinar diam eros in elit. Pellentesque convallis laoreet
                laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className="para-faq">
                How long does the loan approval process take
              </Typography>

            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#6d8eb4" }}>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                amet egestas eros, vitae egestas augue. Duis vel est augue.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className="para-faq">Are there any hidden charges or processing fees?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#6d8eb4" }}>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                amet egestas eros, vitae egestas augue. Duis vel est augue.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div> */}
    </>
  )
}

export default Product