import React from 'react'
import call from "../Images/call.svg";
import instagram from "../Images/instagram.png";
import linkedin from "../Images/linkedin.png";
import facebook from "../Images/facebook.png";
import twitter from "../Images/twitter.png";
import message from "../Images/message.svg";
import Credinlogo from "../Images/Credinlogo.svg";
import "./Footer.css"

const Footer = () => {
    var date = new Date().getFullYear();
    return (
        <>
            <div className='footer'>
                <div className='cred-scanner'>
                    <img src={Credinlogo} className="footer-logo" alt="logo" />
                    <div>
                        <button className="download-btn">
                            <a href="https://play.google.com/store/apps/details?id=com.credin.app" target="_blank" rel="noopener noreferrer">
                                DOWNLOAD OUR APP
                            </a>
                        </button>
                    </div>
                </div>
                <div className='footer-container'>
                    <div className='footer-one'>
                        <div className="grid-item">
                            <ul className='links-one'>
                                <li style={{ fontWeight: "600", color: "#0c4282" }}>QUICK LINKS</li>
                                <li>
                                    <a href="/nbfc/partners">NBFC Partners</a>
                                </li>
                                <li>
                                    <a href="/faq">FAQ</a>
                                </li>
                                <li>
                                    <a href="/privacy/policy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/terms/conditions">Terms and Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='footer-two'>
                        <div className="grid-item">
                            <ul>
                                <li style={{ fontWeight: "600", color: "#0c4282" }}>GET IN TOUCH</li>
                                <li>
                                    <div className='icons-style'>
                                        <img style={{ height: "1.5rem" }} src={call} className="" alt="logo" />
                                        <p>+919879015554</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='icons-style'>
                                        <img style={{ width: "1.5rem" }} src={message} className="contact-logo" alt="logo" />
                                        <span>help@credin.in</span>
                                    </div>
                                </li>
                                <li>
                                <div className="icons-two" >
                                    <a href="https://www.instagram.com/credinindia/" target="_blank" rel="noopener noreferrer">
                                        <img style={{ height: "1.5rem" }} src={instagram} className="" alt="logo" />
                                    </a>
                                    <a href="https://in.linkedin.com/company/upkram" target="_blank" rel="noopener noreferrer">
                                        <img style={{ height: "1.5rem" }} src={linkedin} className="" alt="logo" />
                                    </a>
                                    <a href="https://www.facebook.com/credin25" target="_blank" rel="noopener noreferrer">
                                        <img style={{ height: "1.5rem" }} src={facebook} className="" alt="logo" />
                                    </a>
                                    <a href="https://twitter.com/Credin4" target="_blank" rel="noopener noreferrer">
                                        <img style={{ height: "1.5rem" }} src={twitter} className="" alt="logo" />
                                    </a>
                                </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-three">
                        <div className="grid-item">
                            <ul>
                                <li style={{ fontWeight: "600", color: "#0c4282" }}>Registered office</li>
                                <li>
                                    <div className='icons-style'>
                                        <p>Upkram Technologies Pvt. Ltd.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='icons-style'>
                                        <span>3-4,3rd Floor, Riyaplex,</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='icons-style'>
                                        <span className='directions'>80 Urmi Society, BPC Road,</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='icons-style'>
                                        <span className='directions'>Vadodara, 390 020</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='icons'>
                <div className="icons-one">
                    <p className='footer-para'>All right reserved &copy; Upkram Technologies Pvt.Ltd. @{date}</p>
                </div>
                {/* <div className="icons-two" >
                    <a href="https://www.instagram.com/credinindia/" target="_blank" rel="noopener noreferrer">
                        <img style={{ height: "1.5rem" }} src={instagram} className="" alt="logo" />
                    </a>
                    <a href="https://in.linkedin.com/company/upkram" target="_blank" rel="noopener noreferrer">
                        <img style={{ height: "1.5rem" }} src={linkedin} className="" alt="logo" />
                    </a>
                    <a href="https://www.facebook.com/credin25" target="_blank" rel="noopener noreferrer">
                        <img style={{ height: "1.5rem" }} src={facebook} className="" alt="logo" />
                    </a>
                    <a href="https://twitter.com/Credin4" target="_blank" rel="noopener noreferrer">
                        <img style={{ height: "1.5rem" }} src={twitter} className="" alt="logo" />
                    </a>
                </div> */}
            </div>
        </>
    )
}

export default Footer