import React from 'react'
import './NbfcPartners.css';
import hdb from "../../Images/HDB-logo.png";
import arvog from "../../Images/arvog.jpeg";
import ctl from "../../Images/CTLLogo-01.png";
import lendtree from "../../Images/lendtree-1.jpg";
import maxemo from "../../Images/maxemo.jpeg";
import pincab from "../../Images/pincab.png";
import arthmate from "../../Images/Arthmate-final-logo.png";
import northern from "../../Images/Northern Arc logo.jpg";
import fintree from "../../Images/Fintree.jpg";
import ramaiah from "../../Images/Ramaiah LOGO.jpg";
import trimurti from "../../Images/Trimurti Fincap Logo (4).jpg";
import roha from "../../Images/roha.png";
import LanguageIcon from '@mui/icons-material/Language';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';

const NbfcPartners = () => {
    return (
        <div>
            <div className='section-two-nbfc' id='mnfc-id'>
                <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center", paddingTop: "1rem" }}>NBFC Partners</h2>
                <p className="nbfc-para">Credin proudly collaborates with a robust network of Non-Banking Financial Company (NBFC) partners, each bringing unique strengths and specialised financial expertise. Our NBFC partners are carefully selected for their commitment to innovation, customer service, and financial inclusivity. They play a pivotal role in extending Credin's reach, offering diverse loan products and financial services tailored to meet the varied needs of our customers. These partnerships enable us to offer more flexible, competitive, and accessible financial solutions. Together, we work towards a shared goal of empowering individuals and businesses with the financial tools they need to succeed and grow. Our NBFC partners are more than just collaborators; they are integral to our mission of transforming the landscape of financial services.</p>
                <div className="nbfc-div-one">
                    <div className='section-two-nbfc-div'>
                        <img src={hdb} className="hdb" alt="logo" />
                        <p className="nbfc-heading" >HDB FINANCIAL SERVICES LIMITED</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://www.hdbfs.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://www.hdbfs.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                customer.support@hdbfs.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Mr. Harisn Kumar</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> 044 42984542</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> gro@hdbfs.com</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={pincab} className="pincab" alt="logo" />
                        <p className="nbfc-heading" >PINNACLE CAPITAL SOLUTIONS PVT LTD</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://www.pincap.in/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://www.pincap.in/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                support@pincap.in
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Yogesh Jha</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91 0813019 5773</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> support@pincap.in</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={ctl} className="ctl" alt="logo" />
                        <p className="nbfc-heading" >CAPITAL TRADE LINKS LIMITED</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="http://www.capitaltrade.in/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            http://www.capitaltrade.in/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                info@capitaltrade.in
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Mr. Aryan Chaudhary</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91 7042353322</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> admin@capitaltrade.in</p>
                        </div>
                    </div>
                </div>
                <div className="nbfc-div-two">
                    <div className='section-two-nbfc-div'>
                        <img src={maxemo} className="maxemo" alt="logo" />
                        <p className="nbfc-heading" >MAXEMO CAPITAL SERVICES PVT LTD</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://maxemocapital.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://maxemocapital.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                            contact@maxemocapital.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Mr. Pankaj Dixit</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91 78272 18200</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> Nodalofficer@maxemocapital.com</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={arvog} className="arvog" alt="logo" />
                        <p className="nbfc-heading" >FINKURVE FINANCIAL SERVICES LIMITED</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://www.arvog.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://www.arvog.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                support.loans@arvog.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Mr. Darshit Jain</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +917506339484</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> darshit.jain@arvog.com</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={lendtree} className="lendtree" alt="logo" />
                        <p className="nbfc-heading" >PEERFINTECH SOLUTIONS PVT LTD</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://www.lendtree.in/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://www.lendtree.in/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                support@lendtree.in
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Veena Manglae</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91 9999973029</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> http://veena.Mangla@lendtree.in/</p>
                        </div>
                    </div>

                </div>
                <div className="nbfc-div-three">
                    <div className='section-two-nbfc-div'>
                        <img src={northern} className="northern" alt="logo" />
                        <p className="nbfc-heading" >NORTHERN ARC CAPITAL LIMITED</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://www.northernarc.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://www.northernarc.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                customersupport@northernarc.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Mr. Yuvaraj Sivakumar</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> 1800 4198 766</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> gro@northernarc.com</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={arthmate} className="arthmate" alt="logo" />
                        <p className="nbfc-heading">ARTHMATE FINANCING INDIA PRIVATE LIMITED</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://www.arthmate.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://www.arthmate.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                customercare@arthmate.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Mr. Yogesh Rohilla</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91-7835009643</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> statutory.compliance@arthmate.com</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={fintree} className="fintree" alt="logo" />
                        <p className="nbfc-heading" >FINTREE FINANCE PVT.LTD</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://fintreefinance.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://fintreefinance.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                wecare@fintreefinance.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Ms. Sweta Parekh</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> 18002678111</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> sweta.parekh@fintreefinance.com</p>
                        </div>
                    </div>
                </div>
                <div className="nbfc-div-four">
                    <div className='section-two-nbfc-div'>
                        <img src={roha} className="roha" alt="logo" />
                        <p className="nbfc-heading" >ROHA HOUSING FINANCE PRIVATE LIMITED</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://rohahousing.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://rohahousing.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                customerservices@rohagroup.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Mrinal Agarwal</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91 9987244443</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> mrinal.agarwal@rohagroup.com</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={ramaiah} className="ramaiah" alt="logo" />
                        <p className="nbfc-heading">RAMAIAH CAPITAL PVT. LTD.</p>
                        <div className='web-link'>
                            <LanguageIcon sx={{ fontSize: "1rem" }} />
                            <a href="https://ramaiahcapital.com/" target="_blank" rel="noopener noreferrer" className='link-style'>
                            https://ramaiahcapital.com/
                            </a>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                info@ramaiahcapital.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'>Sri Pradeep Vaddaraase</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91 9663628386</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> chiefmanager@ramaiahcapital.com</p>
                        </div>
                    </div>
                    <div className='section-two-nbfc-div'>
                        <img src={trimurti} className="trimurti" alt="logo" />
                        <p className="nbfc-heading" >TRIMURTI FINCAP PVT. LTD</p>
                        <div className='web-link'>
                            <MailIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'>
                                patelhiteshpg769@gmail.com
                            </p>
                        </div>
                        <p className='grievance'>Grievance Redressal Officer Details</p>
                        <p className='grievance-name'> Hitesh Patel</p>
                        <div className='web-link'>
                            <CallIcon sx={{ fontSize: "1rem" }} />
                            <p className='link-style'> +91 7698889886</p>
                        </div>
                        <div className='web-link'>
                            <MailIcon sx={{ color: "#0c4282", fontSize: "1rem" }} />
                            <p className='link-style'> patelhiteshpg769@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NbfcPartners