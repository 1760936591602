import React, { useState } from 'react'
import './PrivacyPolicy.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const PrivacyPolicy = () => {
    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [accountData, setAccountData] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [borrowerId, setBorrowerId] = useState("");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setOpen(true);
        var reqArray = {
            "requestType": accountData,
            "borrowerName": name,
            "phoneNumber": number,
            "emailId": email,
            "borrowerId": borrowerId
        };

        console.log(reqArray);
        axios
            .post("https://consoleapi.credin.in/v3/email/credin-website/send", {
                dynamic_template_data: reqArray
            })
            .then((res) => {
                console.log(res);
                setOpen(false);
                toast.success(res.data);
                setTimeout(() => {
                    setOpen(false);
                    window.location.reload();
                }, 5000);
            })
            .catch((err) => {
                console.log(err);
                setOpen(false);
                toast.error(err);
            });
    };

    return (
        <div>
            <div className='section-two-privacy'>
                <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center", paddingTop: "1rem", paddingBottom: "1rem" }}>Privacy Policy</h2>
                <div className='accord-privacy'>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>
                                Privacy Policy
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.  This privacy policy (privacy policy) sets forth the modes of collection, use and disclosure of your information gathered on the platform. This privacy policy applies only to personal information collected on the platform. This privacy policy does not apply to information collected by the company in other ways, including information collected offline. Please read this privacy policy carefully. By continuing to use the services, or access the platform you agree to this privacy policy. If you do not agree to this privacy policy, you may not avail the services. <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. Access to the Platform is subject to the Terms and Conditions (Terms) accessible on the Platform, capitalized terms used and not defined in this Privacy Policy, shall have the same meaning ascribed to them in the Terms.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                3. By visiting the website, www.credin.in (“Website”) or the Credin mobile and/or web application (the “App”) (collectively, the “Platform”) and availing the services provided by us (“Services”) you agree to be bound by the terms and conditions of this Privacy Policy.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                4. If you do not agree to this Policy or any part thereof, please do not use/ access/ download or install the Platform or any part thereof.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Introduction</Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. We, Upkram Technologies Pvt Ltd (Company; We; us; our) have developed a mobile application called Credin (Application/ App) and Web Application through which you may apply for a Loan to be granted by various non-banking financial company OR Banks (Lender), based on the loan agreement executed between you and LENDER. LENDER has appointed and authorized us to collect, store, authenticate, verify and distribute the Personal Information (defined below) as may be required by LENDER to sanction the Loan. The Personal Information shall be collected through the mobile application form (Mobile Application Form) available on the Mobile Application and Website login. <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. We are committed to protect your privacy and have made this Privacy Policy to describe the procedures we adhere to for collecting, using, and disclosing the Personal Information. We recommend you to read this Privacy Policy carefully so that you understand our approach towards the use of your Personal Information.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>
                                Personal information collected
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. In order to avail the Services, you are required to register with the Company by downloading the Credin app from Google Play Store or Websit login (User Account). During the application process, you shall be required to share/upload certain personal information, your name, e-mail address, gender, date of birth, mobile number, passwords, photograph, mobile phone information, financial information such as bank documents, salary slips, bank statements, PAN card, bank account no., data from Credit Information Companies, data required for Know Your Customer compliances, requirement and other relevant details (Personal Information). You undertake that you shall be solely responsible for the accuracy and truthfulness of the Personal Information you share with us. <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. You understand and acknowledge that the Company reserves the right to track your location (Track) during the provision of the Services, and also in the event that you stop, cease, discontinue to use or avail the Services, through the deletion or uninstallation of the Mobile App or otherwise, till the event that your obligations to pay the Outstanding Amount(s) to LENDER, exist. Deletion, uninstallation, and/or discontinuation of our Services, shall not release you from the responsibility, obligation and liability to repay the Outstanding Amount(s).
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Governing statute</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. This Privacy Policy is governed by and is compliant with the Information Technology( Reasonable Security Practices and Procedures and Sensitive Personal Data or Information)Rules 2011, which is designed to protect Personal Information of the End-User(s) of the Services; and other applicable rules and regulations related to privacy. <br /><br />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Cookies</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. We may set cookies to track your use of the Platform. Cookies are small encrypted files, that a site or its service provider transfers to your device’s hard drive that enables the sites or service provider’s systems to recognize your device and capture and remember certain information. By using the Application, you signify your consent to our use of cookies.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Disclosures</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. We do not sell, rent, lease your Personal Information to anybody and will never do so. Keeping this in mind, we may disclose your Personal Information in the following cases: <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. Administrators: We shall provide access to your Personal information to our authorized administrative(s) for internal business purposes, who shall be under confidentiality obligations towards the same.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                3. Affiliates: We may provide Personal Information we collect to our affiliates. For example, we may disclose Personal Information to our affiliates in order to respond to your requests for information or the Services, or to help limit your receipt of marketing materials you have requested not to receive.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                4. Business Partners: We may use certain trusted third party companies and individuals to help us provide, analyse, and improve the Services including but not limited to data storage, maintenance services, database management, credit bureaus, rating agencies, web analytics, payment processing, and improvement of the Platform’s features. These third parties may have access to your information only for purposes of performing these tasks on our behalf and under obligations similar to those in this Privacy Policy. We may disclose your Personal Information to partners who perform business functions or hosting services on our behalf and who may be located outside India.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                5. Service Providers: We may share your Personal Information with the service providers, including LENDER, who are working with us in connection with the operation of the Services or the Platform, so long as such service providers are subject to confidentiality restrictions consistent with this Privacy Policy.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                6.Joint Marketing Arrangements: Where permitted by law, we may share your Personal Information with joint marketers with whom we have a marketing arrangement, we would require all such joint marketers to have written contracts with us that specify the appropriate use of your Personal Information, require them to safeguard your Personal Information, and prohibit them from making unauthorized or unlawful use of your Personal Information<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                7.Persons Who Acquire Our Assets or Business: If we sell or transfer any of our business or assets, certain Personal Information may be a part of that sale or transfer. In the event of such a sale or transfer, we will notify you.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                8. Legal and Regulatory Authorities: We may be required to disclose your Personal Information due to legal or regulatory requirements. In such instances, we reserve the right to disclose your Personal Information as required in order to comply with our legal obligations, including but not limited to complying with court orders, warrants, or discovery requests. We may also disclose your Personal Information(a) to law enforcement officers or others; (b) to Credit Information Companies; (c) to comply with a judicial proceeding, court order, or legal process served on us or the Platform; (d) to enforce or apply this Privacy Policy or the Terms of Service or our other policies or Agreements; (e) for an insolvency proceeding involving all or part of the business or asset to which the information pertains; (f) respond to claims that any Personal Information violates the rights of third-parties; (g) or protect the rights, property, or personal safety of the Company, or the general public. You agree and acknowledge that we may not inform you prior to or after disclosures made according to this section.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                9. Notwithstanding anything mentioned hereinabove, the Company shall not be responsible for the actions or omissions of the service providers or parties with whom the Personal Information is shared, nor shall the Company be responsible and/or liable for any additional information you may choose to provide directly to any service provider or any third party.<br /><br />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Data retention</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. We will retain your Personal Information for as long as your registration with us is valid and the Outstanding Amount(s) is due and payable to LENDER. We may also retain and use your Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. Subject to this section, we will delete your Personal Information upon written request for the same received through your registered email-id. Please note, however, that there might be latency in deleting Personal Information from our servers and backed-up versions might exist even after deletion. <br /><br />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Security</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. We value your Personal Information, and protect it on the Platform against loss, misuse or alteration by taking extensive security measures. In order to protect your Personal Information, we have implemented adequate technology and will update these measures as new technology becomes available, as appropriate. All Personal Information is securely stored on a secure cloud setup and all communication happens via bank-grade secure SSL communication channels. The Personal Information is stored on Amazon AWS and Google cloud. Although we provide appropriate firewalls and protections, we cannot warrant the security of any Personal Information transmitted as our systems are not hack proof. Data pilferage due to unauthorized hacking, virus attacks, technical issues is possible and we take no liabilities or responsibilities for it. <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. You are responsible for all actions that take place under your User Account. If you choose to share your User Account details and password or any Personal Information with third parties, you are solely responsible for the same. If you lose control of your User Account, you may lose substantial control over your Personal Information and may be subject to legally binding actions.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Accessing and modifying personal information</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. In case you need to access, review, and/or make changes to the Personal Information, you shall have to login to your User Account and change the requisite details. You shall keep your Personal Information updated to help us better serve you.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Ancilliary services</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. We may provide you with certain ancillary services such as chat rooms, blogs and reviews for the Services. Subject to any applicable laws, any communication shared by you via the Platform or through the blogs, reviews or otherwise to us (including without limitation contents, images, audio, financial information, feedback etc. collectively Feedback) is on a non-confidential basis, and we are under no obligation to refrain from reproducing, publishing or otherwise using it in any way or for any purpose. You shall be responsible for the content and information contained in any Feedback shared by you through the Platform or otherwise to us, including without limitation for its truthfulness and accuracy. Sharing your Feedback with us, constitutes an assignment to us of all worldwide rights, titles and interests in all copyrights and other intellectual property rights in the Feedback and you authorize us to use the Feedback for any purpose, which we may deem fit.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Communications from the platform</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. Special Offers and Updates: We may send you information on products, services, special deals, and a newsletter of the Company. Out of respect for your privacy, we present you with the option of not receiving these types of communications. You may unsubscribe via the unsubscribe mechanism provided in each such communication or by emailing us at help@credin.in <br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                2. Service Announcements: On certain occasions or under law, we are required to send out Service or Platform related announcements. We respect your privacy, however you may not opt-out of these communications. These communications would not be promotional in nature<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                3. Customer Service: We communicate with Customer(s) on a regular basis to provide requested services and in regards to issues relating to their User Account, we reply via email or phone, based on Customer(s) requirements and convenience
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Indemnification</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. You agree to indemnify us, our subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees and Directors and hold us harmless from and against any claims and demand, including reasonable attorneys fees, made by any third party arising out of or relating to: (i) Personal Information and contents that you submit or share through the Platform; (ii) your violation of this Privacy Policy, (iii) or your violation of rights of another Customer(s).                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Limitations of liability</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. You expressly understand and agree that the Company shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, information, details or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services; (ii) unauthorized access to or alteration of your Personal Information.                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Governing laws and duties</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. You expressly understand and agree that the Company, including its directors, officers, employees, representatives or the service provider, shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from; (a) use or the inability to avail the Services (b) inability to use the Platform (c) failure or delay in providing the Services or access to the Platform (d) any performance or non-performance by the Company (e) any damages to or viruses that may infect your electronic devices or other property as the result of your access to the Platform or your downloading of any content from the Platform and (f) server failure or otherwise or in any way relating to the Services.                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Force majeure</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1.This Agreement shall be construed and governed by the laws of India and courts of law at Vadodara, Gujarat shall have exclusive jurisdiction over such disputes without regard to principles of conflict of laws.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Changes to this Policy</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. We may update this Privacy Policy without notice to you. You are encouraged to check this Privacy Policy on a regular basis to be aware of the changes made to it. Continued use of the Services and access to the Platform shall be deemed to be your acceptance of this Privacy Policy.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Your consent</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. By using the Platform and by providing your information, you consent to the collection, sharing, disclosure and usage of the information that you disclose on the Platform in accordance with this Privacy Policy.                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>Grievance officer</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                1. If you have questions, concerns or grievances regarding this Privacy Policy, you can contact below <br /><br />
                                Name: Mr. Bhadresh Mehta <br />
                                Phone: 0265- 4003444<br />
                                Email: bkmehta@credin.in<br />
                                Time: Mon – Sat (10:00 – 18:00)
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginBottom: "1rem", border: "1px solid gray", borderRadius: "9px", padding: "1rem 0" }} expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ color: "#0c4282", fontWeight: "600", fontSize: "1rem" }}>User Account & Data Deletion</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: "#5d80a7" }}>
                                Steps to Delete Account and Associated Data <br /><br />
                                1.	Go at the bottom of olrivacy policy page and click on User Account & Data Deletion <br />
                                2.	Choose the option from Account and Associated Data Deletion OR Only Data Deletion<br />
                                3.	Enter the required details like Name, Mobile, Email & Customer/Borrower ID<br />
                                4.	Submit the request by clicking the SUBMIT Button.<br />
                                5.	After Submission, customer will receive acknowledgement email from Credin Team within 24 hrs and Team will update the request status within 7 days.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                Account Deletion<br />
                                1.	Account will only be deleted if customer has never availed any loan through Credin Platform.<br />
                                2.	If Customer has availed loan through Credin Platform then, account deletion will not happen till the loan is active in the books of the partner lender.Account deletion may get extended subject to confirmation from the lending partner as per the regulatory requirements.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                Data Deletion<br />
                                1.	Data will be deleted if customer has never availed any loan through Credin Platform. In such case, all the customer’s personal identifiers like PAN, masked Aadhar Number, Address, any other KYC details and documents will be deleted and you will no more be able to access any of the services.<br />
                                2.	If customer has any active loan relationship with any of the partner lender, data will will not be deleted till the loan is closed because of regulatory requirements of the partner lender.<br />
                                3.	If customer has closed the loan relationship with the partner lender (availed through Credin platform), customer’s personal identifiers will be delete. Please note, however, that there might be latency in deleting Personal Information from our servers and backed-up versions might exist even after deletion. Also upon withdrawal of such request you might not be further eligible for any services and it shall be at sole discretion of the Company.<br /><br />
                            </Typography>
                            <Typography sx={{ color: "#5d80a7" }}>
                                <div className="inputsField-add">
                                    <form className="add-lead-form" action="" onSubmit={handleSubmit}>
                                        <div className="form-add-lead">

                                            <div className="input-item">
                                                <label>Request Type</label>
                                                <br />
                                                <TextField
                                                    className="input-field"
                                                    required
                                                    select
                                                    name="request-type"
                                                    value={accountData}
                                                    size="small"
                                                    onChange={(e) => setAccountData(e.target.value)}
                                                    placeholder="Borrower Id"
                                                >
                                                    <MenuItem value="Account & Associate Data Deletion">Account & Associate Data Deletion</MenuItem>
                                                    <MenuItem value="Only Data Deletion">Only Data Deletion</MenuItem>
                                                </TextField>
                                            </div> <br />

                                            <div className="input-item">
                                                <label>Name</label>
                                                <br />
                                                <TextField
                                                    className="input-field"
                                                    required
                                                    name="name"
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                    placeholder="Name"
                                                    size="small"
                                                />
                                            </div><br />

                                            <div className="input-item">
                                                <label>Mobile Number</label>
                                                <br />
                                                <TextField
                                                    className="input-field"
                                                    required
                                                    name="mobile-number"
                                                    value={number}
                                                    onChange={(e) => setNumber(e.target.value)}
                                                    placeholder="Mobile Number"
                                                    size="small"
                                                />
                                            </div><br />

                                            <div className="input-item">
                                                <label>E-mail Id</label>
                                                <br />
                                                <TextField
                                                    className="input-field"
                                                    required
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="E-mail Id"
                                                    size="small"
                                                />
                                            </div> <br />

                                            <div className="input-item">
                                                <label>Borrower Id</label>
                                                <br />
                                                <TextField
                                                    className="input-field"
                                                    required
                                                    name="borrower-id"
                                                    value={borrowerId}
                                                    onChange={(e) => setBorrowerId(e.target.value)}
                                                    placeholder="Borrower Id"
                                                    size="small"
                                                />
                                            </div> <br />
                                        </div>

                                        <div>
                                            <Button type="submit" variant="contained">
                                                Submit
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <ToastContainer />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default PrivacyPolicy