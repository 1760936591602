import React from 'react'
import './Team.css';
import linkedin from "../../Images/linkedin.svg";
import birju from "../../Images/BirjuSir.svg";
import mehta from "../../Images/MehtaSir.svg";
import rupesh from "../../Images/RupeshSir.svg";
import sailesh from "../../Images/SaileshSir.svg";

const Team = () => {
    return (
        <div>
            <div className='section-two-team'>
                <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center", paddingTop: "1rem" }}>Meet The Credin Team</h2>
                <p className="team-para">At the heart of Credin lies a dynamic team of finance experts, tech enthusiasts, and customer-centric professionals. Our team is a blend of seasoned veterans and innovative minds, committed to revolutionizing the fintech landscape. We're united by a shared passion for making finance accessible and user-friendly. Our diverse backgrounds in banking, technology, customer service, and entrepreneurship fuel our drive to create financial solutions that genuinely make a difference. Together, we work tirelessly to ensure that Credin not only meets but exceeds the expectations of our customers and stakeholders. With a culture rooted in innovation, collaboration, and integrity, the Credin team is dedicated to building a brighter financial future for everyone.</p>
                <div style={{ borderBottom: "1px solid #85a0c1" }}>
                    <div className="team-first-div">
                        <div className='section-two-team-div'>
                            <img src={rupesh} className="rupesh-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Rupesh Bishnoi</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <a href="https://www.linkedin.com/in/rupeshbishnoi" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedin} alt="LinkedIn logo" />
                                </a>
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={birju} className="birju-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Birju Naik</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <a href="https://www.linkedin.com/in/birju-naik-69657a2b" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedin} alt="LinkedIn logo" />
                                </a>
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={mehta} className="mehta-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>B K Mehta</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Director-Finance</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <a href="https://www.linkedin.com/in/bhadresh-mehta-24934229" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedin} alt="LinkedIn logo" />
                                </a>
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={sailesh} className="sailesh-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Shailesh Gorasia</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}> Director-Credit & Risk</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <a href="https://www.linkedin.com/in/shailesh-gorasia-84578412" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedin} alt="LinkedIn logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="team-second-div">
                        <div className='section-two-team-div'>
                            <img src={rupesh} className="rupesh-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Rupesh Bishnoi</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={birju} className="birju-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Birju Naik</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={mehta} className="mehta-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>B K Mehta</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Director-Finance</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={sailesh} className="sailesh-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Shailesh Gorasia</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}> Director-Credit & Risk</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                    </div>
                    <div className="team-third-div">
                        <div className='section-two-team-div'>
                            <img src={rupesh} className="rupesh-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Rupesh Bishnoi</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={birju} className="birju-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Birju Naik</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={mehta} className="mehta-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>B K Mehta</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}>Director-Finance</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                        <div className='section-two-team-div'>
                            <img src={sailesh} className="sailesh-team" alt="logo" />
                            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Shailesh Gorasia</p>
                            <p style={{ textAlign: "center", color: "#4e76a4" }}> Director-Credit & Risk</p>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
                                <img src={linkedin} className="" alt="logo" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Team