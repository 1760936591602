import React, { useState, useEffect } from 'react'
import customer from "../../Images/Customer service person answering question.svg";
import frame_one from "../../Images/Frame (1).svg";
import frame_two from "../../Images/Frame 179.svg";
import frame_three from "../../Images/Frame 193.svg";
import frame_four from "../../Images/Frame.svg";
import online from "../../Images/Online customer service.svg";
import social from "../../Images/Social media marketing and digital advertising.svg";
import linkedin from "../../Images/linkedin.svg";
import birju from "../../Images/BirjuSir.svg";
import mehta from "../../Images/MehtaSir.svg";
import rupesh from "../../Images/RupeshSir.svg";
import sailesh from "../../Images/SaileshSir.svg";
import "./AboutUs.css"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// import { Link } from "react-router-dom";
import mockups from "../../Images/Mockups.jpg";

const Aboutus = () => {
  const [showOne, setShowOne] = React.useState(false);
  const [showTwo, setshowTwo] = React.useState(false);
  const [showThree, setshowThree] = React.useState(false);
  const [showFour, setshowFour] = React.useState(false);
  const [showFive, setshowFive] = React.useState(false);
  const [showSix, setshowSix] = React.useState(false);
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleClickOne = () => {
    if (showOne) {
      setShowOne(false);
    } else {
      setShowOne(true);
      setshowTwo(false)
      setshowThree(false)
      setshowFour(false)
      setshowFive(false)
      setshowSix(false)
    }
  };
  const handleClickTwo = () => {
    if (showTwo) {
      setshowTwo(false);
    } else {
      setshowTwo(true);
      setShowOne(false);
      setshowThree(false)
      setshowFour(false)
      setshowFive(false)
      setshowSix(false)
    }
  };
  const handleClickThree = () => {
    if (showThree) {
      setshowThree(false);
    } else {
      setshowThree(true);
      setshowTwo(false);
      setShowOne(false);
      setshowFour(false)
      setshowFive(false)
      setshowSix(false)
    }
  };
  const handleClickFour = () => {
    if (showFour) {
      setshowFour(false);
    } else {
      setshowFour(true);
      setshowThree(false);
      setshowTwo(false);
      setShowOne(false);
      setshowFive(false)
      setshowSix(false)
    }
  };
  const handleClickFive = () => {
    if (showFive) {
      setshowFive(false);
    } else {
      setshowFive(true);
      setshowFour(false);
      setshowThree(false);
      setshowTwo(false);
      setShowOne(false);
      setshowSix(false)
    }
  };
  const handleClickSix = () => {
    if (showSix) {
      setshowSix(false);
    } else {
      setshowSix(true);
      setshowThree(false);
      setshowTwo(false);
      setShowOne(false);
      setshowFour(false)
      setshowFive(false)
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>

      <div className='bg-image'></div>
      <div className='bg-text'>
        <p className='bg-text-para'>CRED<span style={{ color: "red" }}>IN</span></p>
      </div>

      <div className='about-section-two'>
        <div className='section-two-heading'>
          <div>
            <p className='heading-circles'>Founded</p>
            <p style={{ color: "#0c4282" }}>2019.</p>
          </div>
          <div>
            <p className='heading-circles'>Live</p>
            <p style={{ color: "#0c4282" }}>2020.</p>
          </div>
          <div>
            <p className='heading-circles'>Omni-Channel</p>
            <p style={{ color: "#0c4282", textAlign: "center" }}>2021.</p>
          </div>
          <div>
            <p className='heading-circles'>Scaling</p>
            <p style={{ color: "#0c4282" }}>2022.</p>
          </div>
          <div>
            <p className='heading-circles'>Scaling</p>
            <p style={{ color: "#0c4282" }}>2022.</p>
          </div>
          <div>
            <p className='heading-circles'>Scaling</p>
            <p style={{ color: "#0c4282" }}>2023.</p>
          </div>
        </div>

        <div className='section-two-circles'>
          {window.innerWidth > 600 ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="#336096">Incorporated the Company.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-incorporated">
                <div className="inner-incorporated"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p><hr className='line' /></span></div>
              </div>
            </HtmlTooltip>
          ) : (
            <HtmlTooltip open={showOne} onClick={handleClickOne}
              title={
                <React.Fragment>
                  <Typography color="#336096">Incorporated the Company.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-incorporated">
                <div className="inner-incorporated"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p><hr className='line' /></span></div>
              </div>
            </HtmlTooltip>
          )}

          {window.innerWidth > 600 ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="#336096">Raised the seed capital of 1 CR.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-raised">
                <div className="inner-raised"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          ) : (
            <HtmlTooltip open={showTwo} onClick={handleClickTwo}
              title={
                <React.Fragment>
                  <Typography color="#336096">Raised the seed capital of 1 CR.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-raised">
                <div className="inner-raised"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          )}
          {window.innerWidth > 600 ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="#336096">Became the only platform to digitise the LAP in India.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-became">
                <div className="inner-became"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          ) : (
            <HtmlTooltip open={showThree} onClick={handleClickThree}
              title={
                <React.Fragment>
                  <Typography color="#336096">Became the only platform to digitise the LAP in India.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-became">
                <div className="inner-became"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          )}

          {window.innerWidth > 600 ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="#336096"> 1. Picked monthly Disbursement of 6 CR.
                  </Typography>
                  <Typography color="#336096"> 2. 5X AUM Growth to 27 CR.
                  </Typography>
                  <Typography color="#336096"> 3. Processed 155CR worth of Loans.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-picked">
                <div className="inner-picked"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          ) : (
            <HtmlTooltip open={showFour} onClick={handleClickFour}
              title={
                <React.Fragment>
                  <Typography color="#336096"> 1. Picked monthly Disbursement of 6 CR.
                  </Typography>
                  <Typography color="#336096"> 2. 5X AUM Growth to 27 CR.
                  </Typography>
                  <Typography color="#336096"> 3. Processed 155CR worth of Loans.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-picked">
                <div className="inner-picked"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          )}

          {window.innerWidth > 600 ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="#336096">1. Received 1.5CR Equity investment from GAIL India.<br />
                  </Typography>
                  <Typography color="#336096"> 2. First Fintech to Go Live with Northern Arc for Digital Secured Loan.
                  </Typography>
                  <Typography color="#336096"> 3. 2X Revenue to 2.4CR.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-received">
                <div className="inner-received"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          ) : (
            <HtmlTooltip open={showFive} onClick={handleClickFive}
              title={
                <React.Fragment>
                  <Typography color="#336096">1. Received 1.5CR Equity investment from GAIL India.<br />
                  </Typography>
                  <Typography color="#336096"> 2. First Fintech to Go Live with Northern Arc for Digital Secured Loan.
                  </Typography>
                  <Typography color="#336096"> 3. 2X Revenue to 2.4CR.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-received">
                <div className="inner-received"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          )}
          {window.innerWidth > 600 ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="#336096">  1. Amongst the Top 10 Fintech from India to get selected for TechSprint with RBI Innovation Hub.
                  </Typography>
                  <Typography color="#336096"> 2. Only fintech To get Selected by StartupIndia for there official Startup Delegation at the Global Event SLUSH 2023.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-amongst">
                <div className="inner-amongst"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          ) : (
            <HtmlTooltip open={showSix} onClick={handleClickSix}
              title={
                <React.Fragment>
                  <Typography color="#336096">  1. Amongst the Top 10 Fintech from India to get selected for TechSprint with RBI Innovation Hub.
                  </Typography>
                  <Typography color="#336096"> 2. Only fintech To get Selected by StartupIndia for there official Startup Delegation at the Global Event SLUSH 2023.
                  </Typography>
                </React.Fragment>
              }
            >
              <div className="outer-amongst">
                <div className="inner-amongst"><span style={{ fontSize: "2rem" }}><p style={{ fontSize: "4rem", paddingLeft: "0.8rem", marginTop: "-2.9rem" }}>.</p></span></div>
              </div>
            </HtmlTooltip>
          )}
        </div>
      </div>
      <div className='about-section-three'>
        <p style={{ textAlign: "center", fontSize: "2rem", fontWeight: "600", color: "#0c4282", textDecoration: "underline", paddingTop: "1rem" }}>Our Mission</p>
        <div className="mission-first-div">
          <p className="mission-para-one"> Our mission is to promote financial inclusion through our tech-enabled distribution. We want to reach out to last-mile creditworthy customers.</p>
          <img style={{ height: "20rem", border: "1px solid #131a20", backgroundColor: "#6a8eb2", borderRadius: "17px" }} src={frame_four} className="" alt="logo" />
        </div>
        <p style={{ textAlign: "center", fontSize: "2rem", fontWeight: "600", color: "#0c4282", marginTop: "2rem", textDecoration: "underline" }}>Our Vision</p>
        <div className="mission-second-div">
          <img style={{ height: "20rem", border: "1px solid #131a20", backgroundColor: "#6a8eb2", borderRadius: "17px" }} src={frame_one} className="" alt="logo" />
          <p className="mission-para-two">We want to create innovative, digitally enabled, and technology-driven lending solutions catering to the diverse credit needs of the borrowers.</p>
        </div>
      </div>

      <div className='about-section-four' style={{ marginTop: "1rem" }} >
        <p style={{ textAlign: "center", fontSize: "2rem", fontWeight: "600", color: "#0c4282", textDecoration: "underline", paddingBottom: "1rem" }}>Credin's Building Blocks</p>
        <div className='building-first-div'>
          <div className='section-four-div'>
            <img src={frame_two} className="" alt="logo" style={{ padding: "0rem 4rem" }} />
            <p style={{ textAlign: "center", textDecoration: "underline", color: "#0c4282", fontWeight: "600", fontSize: "1.3rem", marginTop: "-2rem" }} className='proprietary'>Proprietary Distribution </p>
            <p style={{ textAlign: "center", color: "#7e9cbd", fontSize: "1rem", fontWeight: "500", padding: "1rem 1rem" }} className='crafting'>Crafting pathways with precision, our proprietary distribution ensures seamless access to financial solutions tailored for your needs.</p>
          </div>
          <div className='section-four-div'>
            <img style={{ padding: "0rem 3rem" }} src={customer} className="" alt="logo" />
            <p style={{ textAlign: "center", textDecoration: "underline", color: "#0c4282", fontWeight: "600", fontSize: "1.3rem", marginTop: "-2rem" }} className='customer'>Customer-Centric Product</p>
            <p style={{ textAlign: "center", color: "#7e9cbd", fontSize: "1rem", fontWeight: "500", padding: "1rem 1rem" }} className='innovation'>Innovation at the heart, our customer-centric products are sculpted to enrich your financial journey with tailored excellence.</p>
          </div>
        </div>
        <div className='building-second-div'>
          <div className='section-four-div'>
            <img style={{ padding: "0rem 5rem" }} src={online} className="" alt="logo" />
            <p style={{ textAlign: "center", textDecoration: "underline", color: "#0c4282", fontWeight: "600", fontSize: "1.3rem", marginTop: "-2rem" }} className='excellent'>Excellent Services</p>
            <p style={{ textAlign: "center", fontSize: "1rem", color: "#7e9cbd", fontWeight: "500", padding: "1rem 1rem" }} className='dedicated'>Dedicated to exceeding expectations, our commitment to excellent services is the cornerstone of every interaction, ensuring your satisfaction.</p>
          </div>
          <div className='section-four-div'>
            <img style={{ padding: "0rem 3rem" }} src={social} className="" alt="logo" />
            <p style={{ textAlign: "center", textDecoration: "underline", color: "#0c4282", fontSize: "1.3rem", fontWeight: "600", marginTop: "-2rem" }} className='humongous'>Humongous Credin Network</p>
            <p style={{ textAlign: "center", fontSize: "1rem", color: "#7e9cbd", fontWeight: "500", padding: "1rem 1rem" }} className='uniting'>Uniting opportunities on a vast scale, our humongous Credin Network empowers communities and businesses through a web of reliable connections.</p>
          </div>
        </div>
      </div>

      <div className='about-section-five'>
        <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center", paddingTop: "1rem" }}>Our Application</h2>
        <div className='about-section-five-logo' style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingTop: "1rem" }}>
          <img src={mockups} className="app-mobile" alt="logo" />
          <img style={{ paddingBottom: "2rem", paddingTop: "1rem", height: "7rem" }} src={frame_three} className="" alt="logo" />
        </div>
      </div>

      <div className='about-section-six' style={{ borderBottom: "1px solid #85a0c1" }}>
        <h2 style={{ fontSize: "2rem", textDecoration: "underline", color: "#0c4282", textAlign: "center", paddingTop: "1rem" }}>Founding Team</h2>
        <div className='founding-div'>
          <div className='about-section-six-div'>
            <img src={rupesh} className="rupesh" alt="logo" />
            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Rupesh Bishnoi</p>
            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
              <a href="https://www.linkedin.com/in/rupeshbishnoi" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="LinkedIn logo" />
              </a>
            </div>
          </div>
          <div className='about-section-six-div'>
            <img src={birju} className="birju" alt="logo" />
            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Birju Naik</p>
            <p style={{ textAlign: "center", color: "#4e76a4" }}>Co-Founder</p>
            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
              <a href="https://www.linkedin.com/in/birju-naik-69657a2b" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="LinkedIn logo" />
              </a>
            </div>
          </div>
          <div className='about-section-six-div'>
            <img src={mehta} className="mehta" alt="logo" />
            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>B K Mehta</p>
            <p style={{ textAlign: "center", color: "#4e76a4" }}>Director-Finance</p>
            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
              <a href="https://www.linkedin.com/in/bhadresh-mehta-24934229" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="LinkedIn logo" />
              </a>
            </div>
          </div>
          <div className='about-section-six-div'>
            <img src={sailesh} className="sailesh" alt="logo" />
            <p style={{ textAlign: "center", color: "#0c4282", fontWeight: "700" }}>Shailesh Gorasia</p>
            <p style={{ textAlign: "center", color: "#4e76a4" }}> Director-Credit & Risk</p>
            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
              <a href="https://www.linkedin.com/in/shailesh-gorasia-84578412" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="LinkedIn logo" />
              </a>

            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
          <button className='know-btn'>
            <a href="/our/team">Know our Team</a>
          </button>
        </div>
      </div>
    </>
  )
}

export default Aboutus